import React,{useEffect,useState} from 'react';
import { Spin } from 'antd';
import api from '@/http/api';
import Header from '@/components/header/header';
import Footer from '@/components/footer/footer';
import store from '@/store'

export default function Layout(props) {
  const [pageLoading,setPageLoading] = useState(false);
  const [copyRight,setCopyRight] = useState('');
  const getConfig = async () => {
    const data = await api.config.get();
    return data.data;
  }
  const getDownloadSize = async () => {
    const data = await api.checkDownloadSize.get({params: {type: 'cart'}});
    store.dispatch({type: 'getCartData',cartData: data.data});
  }
  useEffect(() => {
    store.subscribe(() => {
        let storeState = store.getState();
        if(storeState.pageLoadingVal !== undefined){
          setPageLoading(storeState.pageLoadingVal);
        }
      })
  },[])
  useEffect(() => {
    getConfig().then(res => {
      sessionStorage.setItem('config', JSON.stringify(res));
      setCopyRight(res.CONFIG_COPYRIGHT);
    })
  },[])
  useEffect(() => {
    getDownloadSize();
  },[])
  return (
    <>
      <Spin spinning={pageLoading} wrapperClassName="m-spin" size="large">
        <Header />
        <div className={props.className ? props.className + ' main' : 'main'}>
          {props.children}
        </div>
        <Footer className={props.className} copyRight={copyRight}/>
      </Spin>
    </>
  );
}

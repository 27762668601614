import React, { useEffect, useState } from 'react';
import Layout from '@/components/layout/layout';
import Search from '@/components/search/search';
import store from '@/store'
import { ADDCARTCOUNT, SUBTRACTCARTCOUNT, CLEARCARTCOUNT } from '@/store/actions';
import SidebarFilter from '@/components/sidebar-filter/sidebar-filter';
import SidebarRadio from '@/components/sidebarRadio/sidebarRadio';
import SidebarCheckbox from '@/components/sidebarCheckbox/sidebarCheckbox';
import SidebarRangeDate from '@/components/sidebarRangeDate/sidebarRangeDate';
import FilterResult from '@/components/filterResult/filterResult';
import BatchOperation from '@/components/batchOperation/batchOperation';
import MaterialList from '@/components/materialList/materialList';
import TopMenu from '@/components/topMenu/topMenu';
import { Modal, Pagination, ConfigProvider,message } from 'antd';
import { useParams } from 'react-router-dom';
import zhCN from 'antd/lib/locale/zh_CN';

import api from '@/http/api';
import history from '@/history';
import {
  FrownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
const fileIcon = require('@/assets/img/file.png').default;

function Home(props) {
  let { slug } = useParams();
  const [filterList, SetFilterList] = useState([]);
  const [addressList, SetAddress] = useState([]);
  const [showLeftMenu, SetShowLeftMenu] = useState(false);
  const [areaList, SetArea] = useState([]);
  const [behaviorList, SetBehaviorList] = useState([]);
  const [rangeDateArr, SetRangeDateArr] = useState([0, 0]);
  const [resourceTypeList, SetResourceTypeList] = useState([]);
  const [shotList, SetShotList] = useState([]);
  //FIXED 添加机位列表，使用侧边栏单选
  const [modal, SetModal] = useState(false);
  const [downloadModal, SetDownloadModal] = useState(false);
  const [dialogModal, SetDialogModal] = useState(false);
  const [downloadModalSlug, SetDownloadModalSlug] = useState('');
  const [notice, SetNotice] = useState({
    modal: '',
    title: '提示',
    desc: '',
  });
  const [packageDownloadMsg,SetPackageDownloadMsg] = useState({count: '',size: ''});
  const [tagList, SetTagList] = useState([]);
  const [searchWord, SetSearchWord] = useState('');
  const [isBatch, SetIsBatch] = useState(false);
  const [materialList, SetMaterialList] = useState([]);
  const [materialPage, SetMaterialPage] = useState(null);
  const [clearAllFunRequestName, SetClearAllFunRequestName] = useState('');
  const closeFn = () => {
    if (notice.modal === 'selectDownload' || notice.modal === 'cartDownload') {
      history.push('/MyDownload');
    } else {
      onShow();
      SetModal(false);
      SetDownloadModal(false);
      SetDialogModal(false);
    }
  };
  const closeDownloadModal = () => {
    SetDownloadModal(false);
  }
  const selectMaterial = (id) => {
    const newList = materialList.map((item) => {
      if (item.id === id) {
        item.checked = !item.checked;
      }
      return item;
    });
    SetMaterialList(newList);
  };
  const setMaterialStatus = (statusName, id) => {
    const newList = materialList.map((item) => {
      if (item.id === id) {
        item[statusName] = Number(item[statusName]) === 0 ? 1 : 0;
      }
      return item;
    });
    SetMaterialList(newList);
  };
  const clearAllTagList = () => {
    SetTagList([]);
    const matchListTag = [{name: 'species'},{name: 'resource_address'},{name: 'protect_area'},{name: 'resource_type'},{name: 'behavior'},{name: 'date_range'}];
    matchListTag.forEach(item => {
      matchList(item);
    })
  };
  const matchList = (tag) => {
    switch (tag.name) {
      case 'species':
        SetFilterList(loopChecked()(filterList, ''));
        break;
      case 'resource_address':
        SetAddress(
          addressList.map((item) => {
            item.checked = false;
            return item;
          })
        );
        break;
      case 'protect_area':
        SetArea(
          areaList.map((item) => {
            item.checked = false;
            return item;
          })
        );
        break;
      case 'resource_type':
        SetResourceTypeList(
          resourceTypeList.map((item) => {
            item.checked = false;
            return item;
          })
        );
        break;
      case 'behavior':
        //清除行为
        SetBehaviorList(
          behaviorList.map((item) => {
            if (tag.id === item.id) {
              item.checked = false;
            }
            if (!tag.id) {
              item.checked = false;
            }
            return item;
          })
        );
        break;
      case 'date_range':
        //清除日期
        SetRangeDateArr([0, 0]);
        break;
      default:
        break;
    }
  };
  const clearTagList = (removeItem) => {
    SetTagList(tagList.filter((tagListItem) => {
      if(removeItem.name === tagListItem.name){
        return tagListItem.id !== removeItem.id;
      }else{
        return tagListItem;
      }
    }));
    //清除高亮
    //除了行为是多选，其他都是单选，这里可以直接赋值false，清除对应的高亮
    matchList(removeItem);
  };
  const filterMaterialListId = () => {
    let idList = [];
    materialList.forEach((item) => {
      if (item.checked) {
        idList.push(item.id);
      }
    });
    return idList;
  };
  const behaviorCheckAll = () => {};
  const getMenuStatus = (status) => {
    SetIsBatch(status);
  };
  const batchTipsAndReset = (modal, info) => {
    SetNotice({ modal: modal, title: '提示', desc: info });
    const newList = materialList.map((item) => {
      item.checked = false;
      return item;
    });
    SetMaterialList(newList);
    SetModal(true);
  };
  const batchRequest = async (requestName, method) => {
    const selectedList = filterMaterialListId();
    let requestData;
    if (method === 'delete') {
      requestData = {
        params: { resource_id: selectedList.join(',') },
      };
    } else {
      requestData = { data: { resource_id: selectedList } };
    }
    //购物车下载可全部一起下载
    if (selectedList.length > 0 || (requestName === 'cartDownload' && materialList.length > 0)) {
      const data = await api[requestName][method](requestData);
      //如果当前的请求是购物车，则添加购物车角标的数量
      if(requestName === 'cart' && Number(data.status)) {
        const operaType = method === 'delete' ? SUBTRACTCARTCOUNT: ADDCARTCOUNT;
        store.dispatch({type: operaType,cartCount: selectedList.length});
      }
      batchTipsAndReset(requestName, data.info);
    }
  };
  const showDialogModal = (cate) => {
    SetClearAllFunRequestName(cate);
    SetDialogModal(true);
  }
  const clearAllFun = async () => {
    SetDialogModal(false);
    const data = await api[clearAllFunRequestName].delete({params: ''});
    batchTipsAndReset(clearAllFunRequestName, data.info);
    console.log('clearAllFunRequestName', clearAllFunRequestName);
    if(clearAllFunRequestName === 'cart'){
      store.dispatch({type: CLEARCARTCOUNT});
    }
  }
  const collectionClick = async () => {
    //批量收藏
    batchRequest('collection', 'post');
  };
  const addCart = async () => {
    //批量加入购物车
    batchRequest('cart', 'post');
  };
  //取消收藏
  const cancelCollection = async () => {
    batchRequest('collection', 'delete');
  };
  //移出购物车
  const subtractCart = async () => {
    batchRequest('cart', 'delete');
  };
  const showDownloadModal = (downloadModal,isAll) => {
    getPackageMsg(isAll);
    SetDownloadModal(true);
    SetDownloadModalSlug(downloadModal);
  }
  const getPackageMsg = async (isAll) => {
    let params = {};
    if(isAll) {
      params = {
        type: 'cart'
      }
    }else{
      const selectedList = filterMaterialListId();
      params = {type: 'select',resource_id: selectedList.join(',')};
    }
    const downloadData = await api.checkDownloadSize.get({params});
    SetPackageDownloadMsg(downloadData.data);
  }
  const packageDownloadFn = async (requestName) => {
    let requestData = {};
    if(requestName === 'selectDownload') {
      const selectedList = filterMaterialListId();
      requestData = {data: {resource_id: selectedList}};
    }
    const data = await api[requestName].post(requestData);
    if(Number(data.status) === 1){
      history.push('/MyDownload');
    }else{
      message.error(data.info);
    }
  }
  //批量下载
  const selectDownload = async() => {
    if(!filterMaterialListId().length){
      message.error('请选择文件后下载');
      return false;
    }
  
    showDownloadModal('selectDownload',false);
    // batchRequest('selectDownload', 'post');
  };
  //全部下载
  const allDownload = () => {
    showDownloadModal('cartDownload',true);
    // batchRequest('cartDownload', 'post');
  };
  const packageDownload = () => {
    SetDownloadModal(false);
    packageDownloadFn(downloadModalSlug)
  }
  const paginationChange = (page, pageSize) => {
    filterDataRequest(page);
  };
  const fetchListData = async (target, page, params = {}) => {
    const data = await api[target].get({ params: { ...params, page: page } });
    if(data.status > 0){
      const newList = data.data.list.map((item) => {
        if (!item.preview_img) {
          item.preview_img = 'https://www.dute.org/imgplaceholder/272x180';
        }
        item.checked = false;
        return item;
      });
      SetMaterialList(newList);
      SetMaterialPage({
        page: Math.ceil(data.data.count / data.data.per_page),
        count: data.data.count,
        current: page,
        per_page: data.data.per_page,
      });
    }
  };
  const loopLevel = () => {
    return function fn(arr, l) {
      return arr.map((item, i) => {
        item.checked = false;
        item.level = l;
        if (item.children) {
          fn(item.children, l + 1);
        }
        return item;
      });
    };
  };
  const loopChecked = () => {
    return function fn(arr, id) {
      return arr.map((item) => {
        item.checked = false;
        if (item.id === id) {
          item.checked = true;
        }
        if (item.children) {
          fn(item.children, id);
        }
        return item;
      });
    };
  };
  const getSpecies = async (httpConfig = {}) => {
    const data = await api.species.get(httpConfig);
    let obj = {
      value: '物种',
      level: 1,
      children: loopLevel()(data.data, 2),
    };
    SetFilterList([obj]);
  };
  const getSidebarFilterData = async (requestName, setMethodName, httpConfig = {}) => {
    const data = await api[requestName].get(httpConfig);
    setMethodName(
      data.data.map((item) => {
        item.checked = false;
        return item;
      })
    );
  };
  const radioFn = (arr, id) => {
    return arr.map((item) => {
      item.checked = false;
      if (item.id === id) {
        item.checked = true;
      }
      return item;
    });
  };
  const rangeDateSelect = (e) => {
    let newDate = e.map((item,i) => {
      if(i === 0) {
        item.hour(0);
        item.minute(0);
        item.second(0);
      }else{
        item.hour(23);
        item.minute(59);
        item.second(59);
      }
      return item;
    })
    let obj = {
      id: `${Number(newDate[0].valueOf()) / 1000}-${Number(newDate[1].valueOf()) / 1000}`,
      value: `${newDate[0].format('YYYY/MM/DD')}-${newDate[1].format('YYYY/MM/DD')}`,
    };
    filterTagFn('date_range', obj);
    SetRangeDateArr(newDate);
  };
  const baseRadioSelect = (e, name, list, method, cb) => {
    if (e) {
      method(radioFn(list, e.id));
      filterTagFn(name, e);
    }
    if (cb) {
      cb();
    }
    return false;
  };
  const addressSelect = (e) => {
    baseRadioSelect(e, 'resource_address', addressList, SetAddress);
  };
  const areaSelect = (e) => {
    baseRadioSelect(e, 'protect_area', areaList, SetArea);
  };
  const shotSelect = (e) => {
    baseRadioSelect(e, 'shot_device', shotList, SetShotList);
  };
  const behaviorSelect = (e, item) => {
    let newTagList = [];
    if (e) {
      newTagList = tagList.map((item) => {
        return item;
      });
      newTagList.push({ name: 'behavior', text: item.value, id: item.id });
    } else {
      tagList.forEach((j) => {
        if (j.name === 'behavior' && j.id === item.id) {
        } else {
          newTagList.push(j);
        }
      });
    }
    SetTagList(newTagList);
    SetBehaviorList(
      behaviorList.map((j) => {
        if (j.id === item.id) {
          item.checked = !item.checked;
        }
        return j;
      })
    );
  };
  const resourceTypeSelect = (e) => {
    baseRadioSelect(e, 'resource_type', resourceTypeList, SetResourceTypeList);
  };
  const leftMenuToggle = () => {
    SetShowLeftMenu(!showLeftMenu);
  }
  const filterTagFn = (name, e) => {
    let newArr = tagList.filter((item) => item.name !== name);
    newArr.push({ name: name, text: e.value, id: e.id });
    SetTagList(newArr);
  };
  const speciesSelect = (e) => {
    if (e.level !== 1) {
      filterTagFn('species', e);
      SetFilterList(loopChecked()(filterList, e.id));
    }
    return false;
  };
  const getRequestTarget = () => {
    let requestTarget = 'resource';
    if (slug) {
      if (slug === '1') {
        requestTarget = 'collection';
      } else if (slug === '2') {
        requestTarget = 'cart';
      }
    }
    return requestTarget;
  };
  const onShow = () => {
    let requestTarget = getRequestTarget();
    fetchListData(requestTarget, 1);
  };
  const searchHandle = async (value, cb) => {
    SetSearchWord(value);
    if (cb) {
      cb();
    }
  };
  const filterDataRequest = (page=1) => {
    let data = {};
    tagList.forEach((item) => {
      if (data[item.name]) {
        data[item.name] = data[item.name] + ',' + item.id;
      } else {
        data[item.name] = item.id;
      }
    });
    if (searchWord) {
      data.title = searchWord;
    }
    let requestTarget = getRequestTarget();
    fetchListData(requestTarget, page, data);
  };
  const releaseDownloadSize = () => {
    store.dispatch({type: ADDCARTCOUNT,cartCount: 0})
  }
  const initTagList = ()=>{
    let slugParams = {};
    const slugAllParams = {
      1: {
        params: {
          type: 'collect',
        }
      },
      2: {
        params: {
          type: 'cart'
        }
      }
    };
    if(slug){
      slugParams = slugAllParams[slug];
    }
    getSpecies(slugParams);
    getSidebarFilterData('resourceAddress', SetAddress, slugParams);
    getSidebarFilterData('protectArea', SetArea, slugParams);
    getSidebarFilterData('resourceType', SetResourceTypeList, slugParams);
    getSidebarFilterData('shotDevice', SetShotList, slugParams);
    getSidebarFilterData('behavior', SetBehaviorList, slugParams);
    clearAllTagList();
  }
  useEffect(() => {
    clearAllTagList();
    SetIsBatch(false);
    releaseDownloadSize();
  },[slug])
  useEffect(() => {
    filterDataRequest();
  }, [tagList, searchWord]);
  useEffect(() => {
    initTagList();
  }, [slug]);
  
  //更改列表的批量操作
  useEffect(() => {
    if(!isBatch){
      SetMaterialList(materialList.map(item => {
        item.checked = false;
        return item;
      }))
    }
  },[isBatch])
  return (
    <>
      <ConfigProvider local={zhCN}>
        <Layout className="flex flex-direction bg-white">
          <div className="search-wrap">
            <Search
              searchHandle={(value, cb) => {
                searchHandle(value, cb);
              }}
            ></Search>
          </div>
          <div className="container">
            {slug && <TopMenu index={slug}></TopMenu>}
          </div>
          <div className="base-grid l-30 container">
            <div className="l">
              <div className="cu-btn as-a left-menu-title w-260 bg-yellow mb18" onClick={leftMenuToggle}>
                {showLeftMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                <span>高级检索</span>
              </div>
            </div>
            <div className="r">
              <div className="index-menu-operation flex justify-between align-center">
                <FilterResult
                  clearAll={clearAllTagList}
                  clear={clearTagList}
                  tagList={tagList}
                ></FilterResult>
                <BatchOperation
                  showMenu={isBatch}
                  slug={slug}
                  addCart={addCart}
                  collectionClick={collectionClick}
                  getMenuStatus={getMenuStatus}
                  cancelCollection={cancelCollection}
                  subtractCart={subtractCart}
                  selectDownload={selectDownload}
                  allDownload={allDownload}
                  clearAllFun={showDialogModal}
                ></BatchOperation>
              </div>
            </div>
          </div>
          <div className="base-grid container l-30">
            {showLeftMenu && (
              <div className="l">
                <SidebarFilter
                  selectData={speciesSelect}
                  filterList={filterList}
                ></SidebarFilter>
                <SidebarRadio
                  headerTitle="地点"
                  selectData={addressSelect}
                  filterList={addressList}
                ></SidebarRadio>
                <SidebarRadio
                  headerTitle="保护地"
                  selectData={areaSelect}
                  filterList={areaList}
                ></SidebarRadio>
                <SidebarCheckbox
                  checkedAll={behaviorCheckAll}
                  filterList={behaviorList}
                  headerTitle="行为"
                  selectData={behaviorSelect}
                ></SidebarCheckbox>
                <SidebarRadio
                  headerTitle="资源类型"
                  selectData={resourceTypeSelect}
                  filterList={resourceTypeList}
                ></SidebarRadio>
                <SidebarRadio
                  headerTitle="拍摄设备"
                  selectData={shotSelect}
                  filterList={shotList}
                ></SidebarRadio>
                <SidebarRangeDate
                  headerTitle="时间"
                  rangeDate={rangeDateArr}
                  selectData={rangeDateSelect}
                ></SidebarRangeDate>
              </div>
            )}
            <div className={`r`}>
              <MaterialList
                className={`${showLeftMenu ? 'n-3' : 'n-4'}`}
                selectMaterial={selectMaterial}
                setMaterialStatus={setMaterialStatus}
                materialList={materialList}
                isBatch={isBatch}
              ></MaterialList>
              {materialPage && materialPage.count > 0 ? (
                <Pagination
                  className="m-pagination"
                  total={materialPage.count}
                  showSizeChanger={false}
                  showQuickJumper
                  defaultCurrent={1}
                  current={materialPage.current}
                  defaultPageSize={materialPage.per_page}
                  onChange={(page, pageSize) => {
                    paginationChange(page, pageSize);
                  }}
                  showTotal={() => {
                    return `共有${materialPage.page}页`;
                  }}
                />
              ) : (
                <div className="no-data-wrap">
                  <FrownOutlined />
                  <span className="text">没有更多数据了</span>
                </div>
              )}
            </div>
          </div>
          <Modal
            className="m-modal"
            centered
            visible={modal}
            width={365}
            onCancel={() => {
              closeFn();
            }}
          >
            <div className="m-notice-modal-content">
              <p className="modal-title">{notice.title}</p>
              <p className="modal-desc">{notice.desc}</p>
            </div>
          </Modal>
          <Modal
            className="m-dialog-modal m-modal"
            centered
            visible={dialogModal}
            width={365}
            onCancel={() => {
              closeFn();
            }}
          >
            <div className="m-notice-modal-content">
              <p className="modal-title">提示</p>
              <p className="modal-desc">确认要清除全部吗?</p>
              <div className="btn-group">
                <div onClick={closeFn} className="as-a cu-btn sm bg-orange s-round">取消</div>
                <div onClick={clearAllFun} className="as-a cu-btn sm bg-yellow s-round">确定</div>
              </div>
            </div>
          </Modal>
          <Modal
            className="m-modal"
            centered
            visible={downloadModal}
            width={520}
            onCancel={() => {
              closeDownloadModal();
            }}
          >
            <div className="m-download-modal s-m-modal">
              <div className="title-wrap">
                <div className="title">打包下载</div>
              </div>
              <div className="download-msg-wrap">
                <img src={fileIcon} alt=""/>
                <div className="r">
                  <div className="line">
                    <span>文件数量</span>
                    <span className="text-orange">{packageDownloadMsg.count}</span>
                  </div>
                  <div className="line">
                    <span>文件大小</span>
                    <span className="text-orange">{packageDownloadMsg.size}</span>
                  </div>
                </div>
              </div>
              <div className="tips">您所选文件较多，系统会在后台打包后供您下载</div>
              <div className="btn-group">
                <div className="cu-btn as-a bg-yellow sm s-round" onClick={packageDownload}>打包下载</div>
              </div>
            </div>
          </Modal>
        </Layout>
      </ConfigProvider>
    </>
  );
}

export default React.memo(Home);

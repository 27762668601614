import React, { useContext, createContext, useState ,useEffect } from "react";
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import history from './history';
import { GlobalStyle } from '@/assets/global-style';
import Login from "@/application/Login";
import Register from "@/application/Register";
import Home from "@/application/Home";
import User from "@/application/Profile";
import Notice from "@/application/Notice";
import MyCart from "@/application/MyCart";
import MyDownload from "@/application/MyDownload";
import api from "@/http/api";

export default function App() {
  return (
    <ProvideAuth>
      <GlobalStyle></GlobalStyle>
      <Router history={history}>
        <div className="page">
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/notice">
              <Notice />
            </Route>
            <PrivateRoute path="/user">
              <User />
            </PrivateRoute>
            <PrivateRoute path="/MyDownload">
              <MyDownload />
            </PrivateRoute>
            <PrivateRoute path="/myCart">
              <MyCart />
            </PrivateRoute>
            <Route path="/tab/:slug" forceRefresh={true}>
              <Home />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

const auth = {
  signin(cb) {
    api.login.get({loadingConfig: {showLoading: false}}).then(res => {
      if(Number(res.status) === 1)  {
        const data = res.data ? res.data : {name: ''};
        localStorage.setItem('user',JSON.stringify(data));
        cb(JSON.stringify(data));
      }else{
        cb(null);
      }
    })
  },
  signout(cb) {
    localStorage.clear();
    cb();
  }
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
//传递用户的信息，如果不存在，则表示该用户未登录
export const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const signin = cb => {
    return auth.signin((user) => {
      const jsonUser = JSON.parse(user);
      setUser(jsonUser);
      cb(jsonUser);
    });
  };

  const signout = cb => {
    return auth.signout(() => {
      setUser(null);
      history.push('/login');
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
//通过该组件去判断包裹住的页面是否有登录验证。
//如果没验证，则重定向到登录页面
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

import React, { useState } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const valueIcon = require('./sidebar-left.png').default;
export default function Layout(props) {

  console.log("输出一下props", props.filterList);

  const onChangePanel = (key) => {
  };
  const filterData = (event,e) => {
    event.stopPropagation();
    props.selectData(e);
  };
  const genExtra = (item) => {
    return (<div onClick={(e) => {filterData(e,item)}} className="setLevelData">{item.value} <span className="text-light-black ml6">{Number(item.resource_count) ? `(${item.resource_count})` : '' }</span></div>)
  }
  const loopFilter = () => {
    return function fn(children) {
      return children.map((e, i) => {
        if (e.children) {
          return (
            <Collapse onChange={onChangePanel} className={`s-collapse-wrap s-collapse-wrap-${e.level}`} key={i}>
              <Panel header={e.value} key={i}  className={`${e.checked ? 'active' : ''}`} >
                {fn(e.children)}
              </Panel>
            </Collapse>
          );
        } else if(e.level === 2 && !e.children) {
          return (
            <Collapse onChange={onChangePanel} className={`s-collapse-wrap s-collapse-wrap-${e.level}`} key={i}>
              <Panel header={e.value} key={i} className={`${e.checked ? 'active' : ''}`} ></Panel>
            </Collapse>
          )
        } else {
          return (
            <div
              key={i}
              className={`as-a s-collapse-value ${e.checked ? 'active' : ''}`}
              onClick={(o) => {
                filterData(o,e);
              }}
            >
              <img src={valueIcon} alt="" className="icon"/>
              <span className="text">{e.value}</span>
              <span className="count">{Number(e.resource_count) ? `(${e.resource_count})` : '' }</span>
            </div>
          );
        }
      });
    }
  };

  return <>{props.filterList.length > 0 ? loopFilter()(props.filterList) : null}</>;
}

import styled from 'styled-components';
import { BaseSection } from '../../assets/global-style';

//表单框
export const Section = styled(BaseSection)`
  width: 560px;
  margin: 0 auto;
  > h2 {
    font-size: 24px;
    color: #1a1a1a;
    margin-bottom: 28px;
    text-align: center;
  }
  .tips {
      display: flex;
      font-size: 14px;
      color: #999;
      line-height: 1.7;
      margin-bottom: 20px;
      .anticon {
        margin-top: 4px;
        margin-right: 6px;
      }
  }
`;

import React from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function Layout(props) {
  const onChangePanel = (key) => {

  };
  const filterData = (event, e) => {
    event.stopPropagation();
    props.selectData(e);
  };
  const genExtra = () => {
    return (
      <div
        onClick={(e) => {
          filterData(e, '');
        }}
        className="setLevelData"
      >
        {props.headerTitle}
      </div>
    );
  };
  return <>{props.filterList.length > 0 ? <Collapse
    onChange={onChangePanel}
    className={`s-collapse-wrap s-collapse-wrap-1`}
  >
    <Panel header={props.headerTitle} >
      {props.filterList.map((item, i) => {
        return (
          <div
            key={i}
            className={`as-a s-collapse-value ${
              item.checked ? 'active' : ''
            }`}
            onClick={(o) => {
              filterData(o, item);
            }}
          >
            <span className="text">{item.value}</span>
            {Number(item.resource_count) > 0 ? <span className="count">({item.resource_count})</span> : null}
          </div>
        );
      })}
    </Panel>
  </Collapse> : null}</>;
}

// 401未授权
// 403用户被禁用 所有页面无法进入，进入通知页面
// 490未注册
// 406状态被改变

import site from "./site";
import axios from "axios";
import history from '@/history';
import store from '@/store'
import { message } from 'antd'
import { OPENPAGELOADING, CLOSEPAGELOADING,SETNOTICE } from '@/store/actions'

class Request {
  constructor() {
    this.axios = axios.create();
    this.needLoadingRequestCount = 0;
    this.config = {
      url: '',
      baseURL: site.siteUrl,
      method: 'get',
      responseType: 'json',
      params: {},
      headers: {'X-Requested-With': 'XMLHttpRequest','content-type' : 'application/json'},
      data: {},
      timeout: 10000,
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      },
    }
    this.loadingConfig = {
      showLoading: true, // 是否显示请求中的loading
			loadingText: '请求中...',
			loadingTime: 1000, // 在此时间内，请求还没回来的话，就显示加载中动画，单位ms
			timer: null, // 定时器
    }
    this.notShowModalUrlList = []//不显示错误信息弹框的url名单
    //拦截器
    this.interceptor = {
      request: null,
      response: null
    }
  }
  showFullScreenLoading () {
    if (this.needLoadingRequestCount === 0) {
      store.dispatch({type: OPENPAGELOADING})
    }
    this.needLoadingRequestCount++
  }
  
  tryHideFullScreenLoading () {
    if(this.needLoadingRequestCount <= 0) {
      return false;
    }
    this.needLoadingRequestCount--
    if (this.needLoadingRequestCount === 0) {
      store.dispatch({type: CLOSEPAGELOADING})
    }
  }  
  // 该url是否显示弹窗
  filterUrl(url) {
    return this.config.notShowModalUrlList.every(item => {
      return url.indexOf(item) < 0
    })
  }
  // 设置默认配置
  setConfig = (customConfig) => {
    this.config.data = {};
    this.config.params = {};
    if(customConfig.loadingConfig) {
      this.loadingConfig = Object.assign(this.loadingConfig,customConfig.loadingConfig);
      delete customConfig.loadingConfig;
    }
    if(customConfig.headers) {
      let newHeaders = {...this.config.headers,...customConfig.headers};
      customConfig.headers = newHeaders;
      this.config = Object.assign(this.config, customConfig);
    }else{
      this.config = Object.assign(this.config, customConfig);
    }
  }
  // 请求拦截
  interceptorsRequest = () => {
    let that = this;
    this.axios.interceptors.request.use(function (config) {
      if(that.loadingConfig.showLoading) {
        that.showFullScreenLoading()
      }
      if(!that.config.headers["Authorization"]) {
        that.config.headers["Authorization"] = localStorage.getItem('sid');
      }
      return config;
    }, function (error) {
      // 对请求错误做些什么
      if(that.loadingConfig.showLoading) {
        that.tryHideFullScreenLoading()
      }
      message.error('请求超时')
      return Promise.reject(error);
    })
  }

  // 响应拦截
  interceptorsResponse = () => {
    let that = this;
    this.axios.interceptors.response.use(function (response) {
      that.tryHideFullScreenLoading()
      if(response.status === 200 && Number(response.data.status) === 1) {
        if(response.data.sid) {
          localStorage.setItem('sid', response.data.sid);
          that.config.headers["Authorization"] = response.data.sid;
        }
        return response.data;
      }
      if(response.status === 200 && Number(response.data.status) === 0) {
        //FIXED 弹窗
        //弹窗,并返回数据
        return response.data;
      }
      if(response.status === 401 ) { //未授权
        if(window.location.pathname !== '/login') {
          history.push('/login');
        }
        throw '未授权';
      }
      if(response.status === 403 ) {
        localStorage.setItem('noticePage',JSON.stringify({title: '提示',desc: response.data.info}));
        store.dispatch({type: SETNOTICE,noticeConfig: {title: '提示',desc: response.data.info}});
        history.push('/notice');
        throw '该错误为自定义错误';
      }
      if(response.status === 406 ) {
        return {reset: true};
      }
      if(response.status === 490) {
        history.push('/register');
        throw '未注册';
      }
    }, function (error) {
      // 对响应错误做点什么
      that.tryHideFullScreenLoading();
      message.error('服务错误');
      return Promise.reject(error);
    })
  }
  setInterceptors() {
    this.interceptorsRequest();
    this.interceptorsResponse();
  }
  init(config) {
    this.setConfig(config);
    if(localStorage.getItem('sid')){
      this.config.headers["Authorization"] = localStorage.getItem('sid');
    }else{
      delete this.config.headers["Authorization"];
    }
    return this.axios.request(this.config);
  }
}

const instance = new Request();
instance.setInterceptors();
export default instance;
import React,{useState} from 'react';
import { Tag } from 'antd';
import styled from "styled-components";

const FilterWrap = styled.div`
    flex: 0  0 59%;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    color: #999999;
    align-items: center;
    .title{
        
    }
    .tag{
        color: #999999;
    }
`

export default function Index(props) {
    const clearAll = () => {
        props.clearAll();
    }
    const close = (item) => {
        props.clear(item);
    }
    return (
        <FilterWrap className="with-close">
            <p className="title">筛选:</p>
            {
                props.tagList.map((item) => {
                    return (
                        <Tag closable onClose={() => close(item)} className="tag" key={`${item.id}-${item.name}`}>{item.text}</Tag>
                    )
                })
            }
            { props.tagList.length ? <div className="last-tag as-a" onClick={clearAll}>清除全部</div> : null}
        </FilterWrap>
    )
}
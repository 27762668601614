import styled,{ createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`	html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
html, body {
    background: #f2f3f4;;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    text-decoration: none;
    color: #333;
}
#root{
    height: 100%;
    min-height: 100%;
    padding-top: 80px;
    > .page {
        height: 100%;
        .m-spin{
            height: 100%;
            .ant-spin-container{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                min-width: 1180px;
                > .main {
                    flex: 1;
                }
                &.white{
                    background-color: #fff;
                }
            }
        }
    }
}
`;

export const BaseSection = styled.div`
    width: 100%;
    padding: 30px 50px;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px 
		rgba(0, 0, 0, 0.14);
	border-radius: 6px;
`;
const baseUrlDev = 'http://localhost:3000/Api/';
// const baseUrlDev = 'https://cat.t4tstudio.com/Api/';

const baseUrl = 'https://cat.t4tstudio.com/Api/';
const prodUrl = 'https://resources.homingleopards.org/Api/'
let siteUrl = process.env.NODE_ENV === 'development' ? baseUrlDev : baseUrl;
siteUrl = process.env.NODE_ENV === 'production' ? prodUrl: siteUrl;
// eslint-disable-next-line import/no-anonymous-default-export
export default{
    baseUrl,
    baseUrlDev,
    siteUrl
}   
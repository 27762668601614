import React, { useEffect, useState,useRef } from 'react';
import Layout from '@/components/layout/layout';
import Sidebar from '@/components/sidebar/sidebar';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatDate } from "@/util";
import { Pagination } from 'antd';
import {
  FrownOutlined
} from '@ant-design/icons';
import api from '@/http/api';

function Home(props) {
  const downloadFileRef = useRef(null);
  const [userInfo, setUserInfo] = useState({});
  const [downloadList, setDownloadList] = useState([]);
  const [downloadPage,setDownloadPage] = useState(null);
  const [nowDownloadFile,setNowDownloadFile] = useState('');
  const [overdueTime,setOverdueTime] = useState('');
  const getUserInfo = async () => {
    const data = await api.userInfo.get();
    setUserInfo(data.data);
  };
  const paginationChange = (page, pageSize) => {
    console.log(page);
    console.log(pageSize);
    getCartDownloadList(page);
  };
  const getDownloadFile = async (id) => {
    const data = await api.cartDownload.get({params: {id: id}});
    setNowDownloadFile(data.data.url);
    downloadFileRef.current.click();
  }
  const getCartDownloadList = async (page=1) => {
    const data = await api.cartDownload.get({params: {page: page}});
    setDownloadPage({
      page: data.data.page,
      per_page: data.data.per_page,
      count: data.data.count
    })
    setDownloadList(data.data.list.map(item => {
      item.title = `猫盟-${item.id}-${item.create_date}`;
      if(item.create_date) {
        item.date = formatDate(item.create_date * 1000,2);
      }
      return item;
    }));
  };
  useEffect(() => {
    console.log(JSON.parse(sessionStorage.getItem('config')));
    setOverdueTime(JSON.parse(sessionStorage.getItem('config')).BATCH_ZIP_TIMEOUT);
  },[])
  useEffect(() => {
    getUserInfo();
  }, []);
  useEffect(() => {
    getCartDownloadList();
    let timer = setInterval(() => {
      getCartDownloadList();
    },10000)
    return () => {
      clearInterval(timer);
    }
  }, []);
  return (
    <>
      <Layout className="flex justify-center mt20">
        <div className="base-grid l-32 container">
          <div className="l">
            <Sidebar {...userInfo}></Sidebar>
          </div>
          <div className="r">
            <div className="base-section form-section">
              <div className="title-wrap" style={{ marginBottom: '18px' }}>
                <h2 className="title">下载列表</h2>
              </div>
              <div className="section-tips">
                <InfoCircleOutlined />
                <span>
                  所选文件较多时需要系统打包后下载，打包时间根据文件大小数量不同，资源包会在打包完成 {overdueTime} 天后过期
                </span>
              </div>
              <a ref={downloadFileRef} id="downloadFile" href={nowDownloadFile} className="hidden">当前的下载文件</a>
              <div className="download-list">
                {downloadList.length ? downloadList.map((item) => {
                  return (
                    <div className="item" key={item.id}>
                      <div className="l">
                        <p className="title">{item.title}</p>
                        <div className="l-b-wrap">
                          {item.size && <span className="size n">{item.size}</span>}
                          <span className="date n">{item.date}</span>
                          {
                            Number(item.status) !== 0 ? (<span className="progress n">{item.status_text}</span>) : (<span className="progress n">打包中...（{item.progress}%）</span>)
                          }
                        </div>
                      </div>
                      {
                        Number(item.status) === 1 && (<div onClick={() => {getDownloadFile(item.id)}} className="as-a cu-btn w-66 bg-yellow s-round">
                        资源下载
                      </div>)
                      }
                    </div>
                  );
                }) : null}
              </div>
              {downloadPage && downloadPage.count > 0  ?(
              <Pagination
                className="m-pagination"
                total={downloadPage.count}
                showSizeChanger={false}
                showQuickJumper
                defaultCurrent={1}
                defaultPageSize={downloadPage.per_page}
                onChange={(page, pageSize) => {
                  paginationChange(page, pageSize);
                }}
                showTotal={() => {
                  return `共有${downloadPage.page}页`;
                }}
              />
            ) : (
              <div className="no-data-wrap">
                <FrownOutlined />
                <span className="text">没有更多数据了</span>
              </div>
            )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default React.memo(Home);

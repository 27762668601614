import React, { useContext,useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '@/history';
import { authContext } from '@/App';
import Layout from '@/components/layout/layout';
import { YellowRowSection } from '@/assets/style/base-ui';
import api from "@/http/api";

let timer = null;
function LoginPage(props) {
  const [qrCode,SetQrCode] = useState('');
  let location = useLocation();
  let auth = useContext(authContext);
  //从哪里重定向到登录页，登录后，返回原本的页面
  let { from } = location.state || { from: { pathname: '/profile' } };
  
  const getQrCode = () => {
    api.auth.get().then(res => {

      SetQrCode(res.url)
    })
  }

  let login = () => {
    auth.signin((data) => {
      //使用数据的某个字段来判断是否已注册
      if(data) {
        clearInterval(timer);
        history.replace(from);
      }
    });
  };
  useEffect(() => {
    getQrCode();
  },[])
  useEffect(() => {
    //轮询某个接口
    //如果确认登录，并且是首次，跳转到注册页，不是的话，跳转到之前的页面
    timer = setInterval(() => {
      login()
    }, 5000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <Layout className="flex justify-center align-center">
        <YellowRowSection className="login-wrap flex justify-center align-center flex-direction">
          <p className="title">猫盟资源库</p>
          <img src={qrCode} alt="" />
          <p className="tips">请使用微信扫码登录</p>
        </YellowRowSection>
      </Layout>
    </>
  );
}

export default React.memo(LoginPage);

import React from 'react';

export default function Index(props) {
    return (
        <div className={`footer ${props.className}`}>
            <div className="inner-wrap">
                <div className="container">
                    <div className="l flex align-center" dangerouslySetInnerHTML={{ __html: props.copyRight}}></div>
                    <a href="http://quansitech.com" target="_blank" rel="noopener noreferrer">技术支持：全思科技</a>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useContext } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { authContext } from '@/App';

export default function Index(props) {
  let location = useLocation();
  let auth = useContext(authContext);
  const logout = () => {
    auth.signout(function () {
      console.log('登出成功');
    });
  };
  return (
    <div className="sidebar-wrap">
      <Link to="/user" className="avatar-wrap">
        <div className="img-wrap">
          <img src={props.avatar_url} alt="" />
        </div>
        <p className="name">{props.nickname}</p>
      </Link>
      <div className="menu-wrap">
        <Link to="/tab/1" className="menu-wrap-item">
          <div className="title">
            我的收藏 <span>({props.collection_count})</span>
          </div>
          <RightOutlined />
        </Link>
        <Link to="/tab/2" className="menu-wrap-item">
          <div className="title">
            购物车 <span>({props.cart_count})</span>
          </div>
          <RightOutlined />
        </Link>
        <Link
          to="/myDownload"
          className={`${
            location.pathname === 'myDownload' ? 'active' : ''
          } menu-wrap-item`}
        >
          <div className="title">
            下载列表 <span>({props.download_count})</span>
          </div>
          <RightOutlined />
        </Link>
        <div className="menu-wrap-item as-a" onClick={logout}>
            <div className="title">
                退出登录
            </div>
            <RightOutlined />
        </div>
      </div>
    </div>
  );
}

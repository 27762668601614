import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Input } from 'antd';

const { Search } = Input;

const GlobalStyle = createGlobalStyle`
.qs-search-wrap{
    .ant-input-group{
        border-radius: 20px;
        overflow: hidden;
        border: solid 1px #ffd430;
    }
    .ant-input-group-addon{
        border: none;
    }
    .ant-input{
        padding: 7.5px 17px;
        border: none;
        background-color: #fffbec;
        &:focus{
            border: none;
            box-shadow: none;
        }
    }
    > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
        background-color: #fffbec;
        border: none;
    }
    .ant-btn .anticon{
        color: #ffd430;
        font-size: 20px;
    }
}
`;

export default function Index(props) {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [placeholderText, setPlaceholderText] = useState(
    props.placeholderText ? props.placeholderText : '请输入需要搜索的标题'
  );
  const searchClick = (value) => {
    setLoading(true);
    props.searchHandle(value,function () {
      setLoading(false);
    });
  };
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Search
        className="qs-search-wrap"
        size="large"
        placeholder={placeholderText}
        loading={loading}
        enterButton
        onSearch={(value) => {searchClick(value)}}
      />
    </>
  );
}

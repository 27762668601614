import React,{ useEffect,useState} from 'react';
import { Badge } from 'antd';
import store from '@/store'
import { Link } from 'react-router-dom';

export default function Index(props) {
    const [cartCount, SetCartCount] = useState('');
    useEffect(() => {
        store.subscribe(() => {
          let storeState = store.getState();
          if(String(storeState.cartData.count)){
            SetCartCount(storeState.cartData.count);
          }
        })
      },[]);
    
    
    return (
        <div className="top-menu-wrap">
            <div className="tab-wrap">
                <Link to="/tab/1" className={`tab ${props.index === '1' ? 'active' : ''}`}>我的收藏</Link>
                <Link to="/tab/2" className={`tab ${props.index === '2' ? 'active' : ''}`}>
                   <Badge
                       showZero={true}
                       count={cartCount}
                       offset={[8, 0]}
                       style={{ backgroundColor: '#ffd430' }}
                       size="small">我的购物车</Badge>
                </Link>
            </div>
            <Link to="/" className="back">返回首页 ></Link>
        </div>
    )
}

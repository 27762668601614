import request from "./http";
const METHOD = ["get", "post", "put", "delete"];
let requestAll = (url) => {
	let obj = {};
	METHOD.forEach(item => {
		obj[item] = (config) => {
			return request.init({
				url: `/${url}`,
				method: item,
				...config
			})
		}
	})
	return obj;
}
const api = {
	auth: requestAll('Auth'),
	limit: requestAll('Limit'),
	login: requestAll('Login'),
	person: requestAll('Person'),
	userInfo: requestAll('UserInfo'),
	resource: requestAll('Resource'),
	mtsJob: requestAll('MtsJob'),
	collection: requestAll('Collection'),
	cartDownload: requestAll('CartDownload'),
	cart: requestAll('Cart'),
	selectDownload: requestAll('SelectDownload'),
	species: requestAll('Species'),
	resourceDownload: requestAll('ResourceDownload'),
	resourceAddress: requestAll('ResourceAddress'),
	protectArea: requestAll('ProtectArea'),
	resourceType: requestAll('ResourceType'),
	behavior: requestAll('Behavior'),
	config: requestAll('Config'),
	checkRegister: requestAll('CheckRegister'),
	shotDevice: requestAll('ShotDevice'),
	checkDownloadSize: requestAll('CheckDownloadSize')
};
export default api;
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import { formatDate } from '@/util';
import { LoadingOutlined } from '@ant-design/icons';
import VideoPlayer from './videoPlayer';
import api from '@/http/api';
import 'video.js/dist/video-js.css';
import store from '@/store';
import { OPENPAGELOADING, CLOSEPAGELOADING,ADDCARTCOUNT,DECREMENTCARTCOUNT } from '@/store/actions';

export default function Index(props) {
  const downloadFileRef = useRef(null);
  const [modals, setModals] = useState([false]);
  const [detailData, setDetailData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [nowDownloadFile, setNowDownloadFile] = useState('');
  const [videoJsConfig, setVideoJsConfig] = useState({
    autoplay: false,
    controls: true,
    width: 450,
    height: 297,
    sources: [
      {
        src: '',
      },
    ],
  });
  const [player, setPlayer] = useState(null);
  const closeFn = (index) => {
    setModals([false]);
  };
  const stopFn = (e) => {
    e.stopPropagation();
  };
  const addCollection = async (e, item, cate) => {
    e.stopPropagation();
    if (Number(item.collection_status) === 0) {
      await api.collection.post({
        data: { resource_id: [item.id] },
        loadingConfig: { showLoading: false },
      });
    } else {
      await api.collection.delete({
        params: { resource_id: item.id },
        loadingConfig: { showLoading: false },
      });
    }
    if (cate === 'detail') {
      let nowStatus = Number(detailData.collection_status) === 0 ? 1 : 0;
      setDetailData({ ...detailData, collection_status: nowStatus });
    }
    props.setMaterialStatus('collection_status', item.id);
  };
  const addCart = async (e, item, cate) => {
    e.stopPropagation();
    if (Number(item.cart_status) === 0) {
      await api.cart.post({
        data: { resource_id: [item.id] },
        loadingConfig: { showLoading: false },
      });
      store.dispatch({type: ADDCARTCOUNT});
    } else {
      await api.cart.delete({
        params: { resource_id: item.id },
        loadingConfig: { showLoading: false },
      });
      store.dispatch({type: DECREMENTCARTCOUNT});
    }
    if (cate === 'detail') {
      let nowStatus = Number(detailData.cart_status) === 0 ? 1 : 0;
      setDetailData({ ...detailData, cart_status: nowStatus });
    }
    props.setMaterialStatus('cart_status', item.id);
  };
  const downloadHandle = async (e, file) => {
    e.stopPropagation();
    setDownloadLoading(true);
    store.dispatch({ type: OPENPAGELOADING });
    const data = await api.resourceDownload.get({
      params: { id: file.id },
      loadingConfig: { showLoading: false },
    });
    setNowDownloadFile(data.data);
    store.dispatch({ type: CLOSEPAGELOADING });
    setDownloadLoading(false);
    downloadFileRef.current.click();
  };
  const showDetail = async (id) => {
    const detailData = await api.resource.get({ params: { id: id } });
    if (detailData.data.preview_mts_job_id) {
      const mtsJobData = await api.mtsJob.get({
        params: { mts_job_id: detailData.data.preview_mts_job_id },
      });
      setVideoJsConfig({
        ...videoJsConfig,
        sources: [{ src: mtsJobData.data.url }],
      });
    }
    setDetailData(detailData.data);
    setModals([true]);
  };
  const selectMaterial = (i) => {
    props.selectMaterial(i);
  };
  const getPlayer = (player) => {
    setPlayer(player);
  };
  return (
    <>
      <a
        ref={downloadFileRef}
        target="__blank"
        id="downloadFile"
        href={nowDownloadFile}
        className="hidden"
      >
        当前的下载文件
      </a>
      <div className={`material-list ${props.className}`}>
        {props.materialList.map((item) => {
          return (
            <div
              className={`item ${props.isBatch ? 'show-batch' : ''} ${
                item.checked ? 'active' : ''
              }`}
              key={item.id}
              onClick={() => {
                showDetail(item.id);
              }}
            >
              <div className="check-box-wrap">
                <input
                  onChange={() => {
                    selectMaterial(item.id);
                  }}
                  onClick={(e) => {
                    stopFn(e);
                  }}
                  checked={item.checked}
                  type="checkbox"
                  className="checkbox"
                />
              </div>
              <div className="img-box flex align-center">
                <img src={item.preview_img} alt="" className="img" />
              </div>
              <div className="content">
                <p className="title text-cut-2">{item.title}</p>
                <div className="menu-bottom">
                  <i
                    onClick={(e) => {
                      addCollection(e, item, 'list');
                    }}
                    className={`icon ${
                      item.collection_status === 1
                        ? 'icon-collection-o'
                        : 'icon-collection-b'
                    }`}
                  ></i>
                  <i
                    onClick={(e) => {
                      addCart(e, item);
                    }}
                    className={`icon ${
                      item.cart_status === 1
                        ? 'icon-shopping-o'
                        : 'icon-shopping-b'
                    }`}
                  ></i>
                  <i
                    onClick={(e) => {
                      showDetail(item.id);
                    }}
                    className={`icon icon-download-b`}
                  ></i>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        className="detail-modal m-modal"
        centered
        forceRender
        visible={modals[0]}
        width={890}
        onCancel={() => {
          closeFn(0);
        }}
      >
        <div className="material-list-detail-wrap">
          <div className="title-wrap">
            <p className="title">{detailData.title}</p>
            <div
              className="r-collection"
              onClick={(e) => {
                addCollection(e, detailData, 'detail');
              }}
            >
              <i
                className={`icon ${
                  detailData.collection_status === 0
                    ? 'icon-border-collection-o'
                    : 'icon-collection-o'
                }`}
              ></i>
              <span>收藏</span>
            </div>
          </div>
          {detailData.copyright ? (
            <div className="city-list">
              {detailData.copyright.map((item, i) => {
                return <span key={i}>{item.title}</span>;
              })}
            </div>
          ) : null}
          <div className="l-img-r-content detail-wrap">
            {detailData.preview_mts_job_id && modals[0] ? (
              <div className="img">
                <VideoPlayer {...videoJsConfig} getPlayer={getPlayer} />
              </div>
            ) : (
              <div
                className="img"
                style={{ backgroundImage: `url(${detailData.preview_img})` }}
              ></div>
            )}
            <div className="content">
              <div className="title">简介</div>
              <div className="desc">{detailData.summary}</div>
              <div className="btn-group">
                {downloadLoading ? (
                  <div className="cu-btn bg-yellow w-136 s-round">
                    <LoadingOutlined />
                  </div>
                ) : (
                  <div
                    className="cu-btn as-a bg-yellow w-136 s-round"
                    onClick={(e) => {
                      downloadHandle(e, detailData);
                    }}
                  >
                    <i className="icon-download-b icon"></i>
                    <span>下载</span>
                  </div>
                )}
                <div
                  className="cu-btn as-a bg-gray w-136 s-round"
                  onClick={(e) => {
                    addCart(e, detailData, 'detail');
                  }}
                >
                  {Number(detailData.cart_status) === 0 ? (<><i className="icon-shopping-b icon"></i><span>加入购物车</span></>) : (<><i className="icon-shopping-o icon"></i><span>移出购物车</span></>)}
                </div>
              </div>
            </div>
          </div>
          <div className="cate-list-wrap">
            {detailData.generate_time && (
              <div className="item">
                时间：{formatDate(detailData.generate_time * 1000, 2)}
              </div>
            )}
            {detailData.resource_address_title && (
              <div className="item">
                地点：{detailData.resource_address_title}
              </div>
            )}
            {detailData.protect_area_title && (
              <div className="item">
                保护地：{detailData.protect_area_title}
              </div>
            )}
            {detailData.shot_device_title && (
              <div className="item">
                拍摄设备：{detailData.shot_device_title}
              </div>
            )}
            {detailData.species_title && (
              <div className="item">分类：{detailData.species_title}</div>
            )}
            {detailData.name && (
              <div className="item">命名：{detailData.name}</div>
            )}
            <div className="item">资源：{detailData.resource_type_title}</div>
            {detailData.number_title && (
              <div className="item">个体数：{detailData.number_title}</div>
            )}
            {detailData.behavior ? (
              <div className="item">
                行为：
                {detailData.behavior.map((item, i) => {
                  return <span key={i}>{item.title}</span>;
                })}
              </div>
            ) : null}
            {detailData.machine_position && (
                <div className="item">机位：{detailData.machine_position}</div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

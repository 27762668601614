import React from 'react';
import { Collapse,Checkbox } from 'antd';
const { Panel } = Collapse;

export default function Layout(props) {
  const checkedAll = (e) => {
    e.stopPropagation();
    props.checkedAll();
  }
  const filterData = (event,item) => {
    props.selectData(event.target.checked,item);
  };
  const genExtra = () => {
    return (
      <div
        onClick={(e) => {
          checkedAll(e);
        }}
        className="setLevelData"
      >
        {props.headerTitle}
      </div>
    );
  };
  return <>{props.filterList.length > 0 ? <Collapse
    className={`s-collapse-wrap s-collapse-wrap-1`}
  >
    <Panel header={props.headerTitle} >
        {
            props.filterList.map(item => {
                return (<Checkbox key={item.id} className="collapse-checkbox" checked={item.checked} onChange={(e) => {filterData(e,item)}} >{item.value}</Checkbox>)
            })
        }
    </Panel>
  </Collapse> : null}</>;
}

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import store from '@/store'
import styled, { createGlobalStyle } from 'styled-components';
import api from '@/http/api';

import {
  HeaderWrapper,
  Container,
  Logo,
  Menu,
  LogoContainer,
} from './header-style.js';
import { Popover } from 'antd';
import { authContext } from '@/App';

const logo = require('@/assets/img/logo.png').default;
const collection = require('@/assets/img/collection.png').default;
const shopping = require('@/assets/img/shopping.png').default;
const userIcon = require('@/assets/img/user.png').default;

const PopoverInner = createGlobalStyle`
    .ant-popover-inner-content {
        background-color: #fff;
        box-shadow: 0px 4px 20px 0px
            rgba(0, 0, 0, 0.14);
    }
`;
const UserMenuWrap = styled.div`
  background-color: #ffffff;
  .menu-item {
    font-size: 12px;
    display: block;
    color: #666666;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: #f48604;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default function Header(props) {
  const [isRegister, setIsRegister] = useState('');
  const [cartCount, setCartCount] = useState('');
  let auth = useContext(authContext);
  const logout = () => {
    auth.signout(function () {
      console.log('登出成功');
    });
  };
  const userMenuContent = (
    <UserMenuWrap>
      <Link to="/user" className="menu-item">
        个人资料
      </Link>
      <div className="menu-item" onClick={logout}>
        退出登录
      </div>
    </UserMenuWrap>
  );
  const checkRegister = () => {
    api.checkRegister.get().then(user => {
      if (user.status > 0) {
        setIsRegister('1');
      }
    });
    
  };
  useEffect(() => {
    checkRegister();
  }, []);
  useEffect(() => {
    store.subscribe(() => {
      let storeState = store.getState();
      if(String(storeState.cartData.count)){
        setCartCount(storeState.cartData.count);
      }
    })
  }, []);
  return (
    <HeaderWrapper>
      <Container>
        <LogoContainer as={Link} to="/">
          <Logo as="img" src={logo} />
        </LogoContainer>
        <Menu>
          {isRegister ? (
            <>
              <Link to="/tab/1" className="menu-item">
                <img src={collection} alt="" />
                <span className="text">收藏</span>
              </Link>
              <Link to="/tab/2" className="menu-item">
                <Badge
                    showZero={true}
                    count={cartCount} style={{ backgroundColor: '#ffd430' }}  size="small">
                  <>
                  <img src={shopping} alt="" />
                  <span className="text">购物车</span>
                  </>
                </Badge>
              </Link>
            </>
          ) : null}
          <Popover className="m-popover" content={userMenuContent} placement="bottom">
            <Link to="/myDownload" className="user-icon">
              <img src={userIcon} alt="" />
            </Link>
          </Popover>
          <PopoverInner />
        </Menu>
      </Container>
    </HeaderWrapper>
    
  );
}

export const OPENPAGELOADING = 'OpenPageLoading'
export const CLOSEPAGELOADING = 'ClosePageLoading'
export const SETWEBCONFIG = 'SetWebConfig'
export const GETWEBCONFIG = 'GetWebConfig'
export const SETNOTICE = 'setNotice'
export const GETCARTDATA = 'getCartData'
export const ADDCARTCOUNT = 'addCartCount'
export const SUBTRACTCARTCOUNT = 'subtractCartCount'
export const CLEARCARTCOUNT = 'clearCartCount'
export const DECREMENTCARTCOUNT = 'decrementCartCount'

import React, { useEffect, useState } from 'react';
import Layout from '@/components/layout/layout';
import history from '@/history';
import { Section } from './style';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import store from '@/store'
import { SETNOTICE } from '@/store/actions'
import api from "@/http/api";

const layout = {
  layout: 'vertical',
  size: 'large',
};

function Home(props) {
  // const { route } = props;
  const [loading, setLoading] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [agreement,setAgreement] = useState('');
  const [noticeModal,setNoticeModal] = useState(false);
  const [notice,setNotice] = useState({title: '提示',desc: ''});
  const showAgreement = (e) => {
    e.preventDefault()
    setAgreementModal(true);
  }
  const onFinish = (values) => {
    setLoading(true);
    api.person.post({
      data: values
    }).then(res => {
      if(Number(res.status) ===  0) {
        setNotice({...notice,desc: res.info});
        setNoticeModal(true);
        setLoading(false);
        return false;
      }else{
        store.dispatch({type: SETNOTICE,noticeConfig: {title: '提交完成',desc: '请等待工作人员审核'}});
        history.push('/notice');
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    setAgreement(JSON.parse(sessionStorage.getItem('config')).REGISTER_AGREEMENT);
  },[])
  return (
    <>
      <Layout className="flex justify-center align-center">
      <Modal
            className="m-modal"
            centered
            visible={noticeModal}
            width={365}
            onCancel={() => {
              setNoticeModal(false)
            }}
          >
            <div className="m-notice-modal-content">
              <p className="modal-title">{notice.title}</p>
              <p className="modal-desc">{notice.desc}</p>
            </div>
          </Modal>
      <Modal
            className="m-modal s-m-modal"
            centered
            visible={agreementModal}
          onOk={() => setAgreementModal(false)}
          onCancel={() => setAgreementModal(false)}
          >
          <div className="m-download-modal">
              <div className="title-wrap">
                <div className="title">《猫盟CFCA资源库使用协议》</div>
              </div>
              <div className="article-content" dangerouslySetInnerHTML={{ __html: agreement}}></div>
          </div>
        </Modal>
        <Section>
          <h2>请完善您的身份资料</h2>
          <p className="tips">
            <InfoCircleOutlined />
            <span>
              检测到您首次登录，请完善您的资料信息，由管理员审核通过后，可访问、使用资源库。
            </span>
          </p>
          <Form
            {...layout}
            name="basic"
            className="qs-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="姓名"
              name="nickname"
              rules={[{ required: true, message: '请输入姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="手机"
              name="mobile"
              rules={[{ required: true, message: '请输入您的手机' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="职业"
              name="vocation"
              rules={[{ required: true, message: '请输入您的职业' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="所属机构"
              name="org_name"
              rules={[{ required: true, message: '请输入您的机构' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('请先阅读并同意协议'),
                },
              ]}
            >
                <Checkbox>我已阅读并同意<span className="as-a text-blue" onClick={(e) => showAgreement(e)}>《猫盟CFCA资源库使用协议》</span></Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                className="antd-btn large btn-orange"
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                提交审核
              </Button>
            </Form.Item>
          </Form>
        </Section>
        
      </Layout>
    </>
  );
}

export default React.memo(Home);

import React, { useState } from 'react';
import { DatePicker, Collapse } from 'antd';
const { Panel } = Collapse;

const { RangePicker } = DatePicker;

export default function Index(props) {
  const filterData = (e) => {
    props.selectData(e);
  };
  const genExtra = () => {
    return (
      <div
        className="setLevelData"
      >
        {props.headerTitle}
      </div>
    );
  };
  return (
    <Collapse className={`s-collapse-wrap s-collapse-wrap-1 range-date-wrap`}>
      <Panel header={props.headerTitle}>
        <RangePicker value={props.rangeDate} onChange={(e) => {filterData(e)}}/>
      </Panel>
    </Collapse>
  );
}

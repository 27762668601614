import api from '@/http/api';
import { OPENPAGELOADING,
  CLOSEPAGELOADING ,
  SETWEBCONFIG ,
  SETNOTICE,
  GETCARTDATA,
  ADDCARTCOUNT,
  SUBTRACTCARTCOUNT,
  CLEARCARTCOUNT,
  DECREMENTCARTCOUNT
} from '../actions'
const initState = {
  pageLoadingVal: false,
  cartData: {},
  noticeConfig: null
}

const getConfig = async () => {
  const data = await api.config.get();
  return data.data;
}
const addCartCountFn = (state,count) => {
  let newCount =  '';
  if(Number(count) >= 0) {
    newCount = Number(count);
  }else{
    newCount = 1;
  }
  return {
    ...state,
    cartData: {...state.cartData,count: Number(state.cartData.count) + newCount}
  }
}
const subtractCartCountFn = (state,count) => {
  return {
    ...state,
    cartData: {...state.cartData,count: state.cartData.count - count}
  }
}
const clearCartCountFn = (state) => {
  return {
    ...state,
    cartData: {...state.cartData,count: 0}
  }
}
const AppReducer = (state=initState, action) => {
  switch (action.type) {
    case OPENPAGELOADING:
      return {
        ...state,
        pageLoadingVal: true
      }
    case CLOSEPAGELOADING:
      return {
        ...state,
        pageLoadingVal: false
      }
    case SETWEBCONFIG:
      return getConfig();
    case SETNOTICE:
      return {
        ...state,
        noticeConfig: action.noticeConfig
      }
    case GETCARTDATA:
      return {
        ...state,
        cartData: action.cartData
      }
    case ADDCARTCOUNT:
      return addCartCountFn(state,action.cartCount)
    case SUBTRACTCARTCOUNT:
      return subtractCartCountFn(state,action.cartCount)
    case CLEARCARTCOUNT:
      return clearCartCountFn(state)
    case DECREMENTCARTCOUNT:
      return {
        ...state,
        cartData: {...state.cartData,count: state.cartData.count - 1}
      }
    default: {
      return state
    }
  }
}

export default AppReducer

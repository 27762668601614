import styled from "styled-components";

export const Section = styled.div`
    background-color: #ffffff;
	box-shadow: 0px 4px 20px 0px 
		rgba(0, 0, 0, 0.14);
	border-radius: 6px;
`

export const YellowRowSection = styled(Section)`
    position: relative;
    &:after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 5px;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        background-color: #ffd430;
    }
`
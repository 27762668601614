import React, { useState } from 'react';
import styled from 'styled-components';

const TagWrap = styled.div`
  display: flex;
  .tag {
    font-size: 14px;
    display: flex;
    padding: 6px 10px;
    border-radius: 3px;
    color: #4d4d4d;
    background-color: #ededed;
    & + .tag {
      margin-left: 12px;
    }
    &.active {
      background-color: #fff0df;
      color: #f48604;
    }
  }
`;

export default function Index(props) {
  const toggleMenu = () => {
    props.getMenuStatus(!props.showMenu);
  };
  const collection = () => {
    props.collectionClick();
  };
  const addCart = () => {
    props.addCart();
  };
  const clearAll = (cate) => {
    props.clearAllFun(cate);
  }
  const cancelCollection = () => {
    props.cancelCollection();
  };
  const subtractCart = () => {
    props.subtractCart();
  };
  const selectDownload = () => {
    props.selectDownload();
  };
  const allDownload = () => {
    props.allDownload();
  };
  const getMenu = () => {
    if (props.slug === '2') {
      return (
        <>
          <div className="as-a tag active" onClick={toggleMenu}>
            批量操作
          </div>
          <div className="as-a tag active" onClick={allDownload}>
            打包下载
          </div>
        </>
      );
    } else {
      return (
        <div className="as-a tag active" onClick={toggleMenu}>
          批量操作
        </div>
      );
    }
  };
  const getSubMenu = () => {
    if (props.slug) {
      if (props.slug === '1') {
        return (
          <>
            <div className="as-a tag active" onClick={toggleMenu}>
              取消批量
            </div>
            <div className="as-a tag" onClick={cancelCollection}>
              取消收藏
            </div>
            <div className="as-a tag" onClick={addCart}>
              加入购物车
            </div>
            <div className="as-a tag" onClick={() => {clearAll('collection')}}>
              清除全部
            </div>
          </>
        );
      }
      if (props.slug === '2') {
        return (
          <>
            <div className="as-a tag active" onClick={toggleMenu}>
              取消批量
            </div>
            <div className="as-a tag" onClick={subtractCart}>
              移出
            </div>
            <div className="as-a tag" onClick={selectDownload}>
              打包下载
            </div>
            <div className="as-a tag" onClick={() => {clearAll('cart')}}>
              清除全部
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <div className="as-a tag active" onClick={toggleMenu}>
            取消批量
          </div>
          <div className="as-a tag" onClick={collection}>
            收藏
          </div>
          <div className="as-a tag" onClick={addCart}>
            加入购物车
          </div>
        </>
      );
    }
  };
  return <TagWrap>{props.showMenu ? getSubMenu() :getMenu() }</TagWrap>;
}

import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import Layout from '@/components/layout/layout';
import api from '@/http/api';
import styled from 'styled-components';
import { BaseSection } from '../../assets/global-style';
import store from '@/store'
import history from '@/history';

const successIcon = require('@/assets/img/success.png').default;

//表单框
export const Section = styled(BaseSection)`
  width: 420px;
  margin: 0 auto;
  padding: 70px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon-tis {
    width: 50px;
    height: 50px;
  }
  > img{
    margin-bottom: 20px;
  }
  > h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
    text-align: center;
  }
  .tips {
    font-size: 14px;
    color: #666666;
    text-align: center;
    line-height: 1.7;
  }
  > a{
    width: 100%;
    margin-top: 40px;
    .cu-btn{
      font-size: 14px;
      height: 40px;
    }
  }
`;

function Notice(props) {
  const [noticeConfig,setNoticeConfig] = useState({showIcon: false,title: '',desc: '',btnList: []});
  const getUserInfo = () => {
    api.userInfo.get().then(res => {
      if(res.data) {
        history.push('/');
      }
      if(res.reset){
        getUserInfo();
      }
    })
  }
  useEffect(() => {
    getUserInfo();
  },[])
  useEffect(() => {
    store.subscribe(() => {
      let storeState = store.getState();
      if(storeState.noticeConfig){
        setNoticeConfig(storeState.noticeConfig);
      }else if(!noticeConfig){
        setNoticeConfig({title: '',desc: '当前没有提示'});
      }
    })
  })
  return (
    <>
      <Layout className="flex justify-center align-center">
        <Section>
          {noticeConfig.showIcon && <img src={successIcon} alt=""/>}
          <h2>{noticeConfig.title}</h2>
          <p className="tips">{noticeConfig.desc}</p>
          {
            noticeConfig.btnList && noticeConfig.btnList.length > 0 ? (<Link to={noticeConfig.btnList[0].url}><div className="cu-btn bg-yellow block">{noticeConfig.btnList[0].text}</div></Link>) : null
          }
        </Section>
      </Layout>
    </>
  );
}

export default React.memo(Notice);

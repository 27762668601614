import styled from 'styled-components';
const HeaderWrapper = styled.div`
    background-color: #ffffff;
	box-shadow: 0px 3px 8px 0px 
		rgba(0, 0, 0, 0.08);
    width: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    padding-top: 7px;
    padding-bottom: 7px;
`
const Container = styled.div`
    width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LogoContainer = styled.a`display: block;font-size: 0;`

const Logo = styled.img`
    height: 66px;
    width: auto;
`
const Menu = styled.div`
    display: flex;
    align-items: center;
    .menu-item{
        display: flex;
        align-items: flex-end;
        color: #666666;
        font-size: 14px;
        &{
            margin-right: 30px;
        }
        > img{
            height: 18px;
            width: auto;
            margin-bottom: 3px;
            &.download{
                height: 16px;
            }
        }
        .text{
            margin-left: 8px;
            line-height: 18px;
        }
        &:hover{
            .text{
                color: #f48604;
            }
        }
    }
    .user-icon{
        width: 30px;
	    height: 30px;
        background-color: #ffd430;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        > img {
            width: 14px;
            height: auto;
        }
    }
`
export {
    HeaderWrapper,
    Container,
    Logo,
    LogoContainer,
    Menu
}
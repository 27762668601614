/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '@/components/layout/layout';
import { Form, Input, Button, Modal } from 'antd';
import Sidebar from '@/components/sidebar/sidebar';
import api from '@/http/api';
import history from '@/history';

const layout = {
  layout: 'horizontal',
  size: 'large',
  labelCol: { span: 3 },
  wrapperCol: { span: 14 },
};
function Home(props) {
  const [loadings, setLoadings] = useState([false, false]);
  const [noticeModal, setNoticeModal] = useState(false);
  const [notice, setNotice] = useState({ title: '提示', desc: '提交成功' });
  const [userInfo, setUserInfo] = useState({});
  const [userInfoValues, setUserInfoValues] = useState(null);
  const [showAvatarTips,setShowAvatarTips] = useState(false);
  const closeFn = () => {
    setNoticeModal(false);
    //更新信息
    getUserInfo();
  }
  const onFinish = (values) => {
    if($('#avatar').attr('value')) {
      let newValues = { ...values, avatar: $('#avatar').attr('value') };
      setLoadings([true, false]);
      api.person
        .put({
          data: newValues,
        })
        .then((res) => {
          if (Number(res.status) === 1) {
            setNotice({ ...notice, desc: '提交成功' });
            setNoticeModal(true);
          } else {
            setNotice({ ...notice, desc: res.info });
            setNoticeModal(true);
          }
          setLoadings([false, false]);
        });
    }else{
      setShowAvatarTips(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const initAvatarComponent = (data) => {
    $('#avatar').attr('data-srcjson', JSON.stringify([data.data.avatar_url]));
    $('#avatar').attr('value', data.data.avatar_url);
    console.log($('#avatar').attr('value'))
    console.log(data.data.avatar_url)
    $('#avatar').ossuploader({
      url: '/extends/AliyunOss/policyGet/type/image',
      oss: true,
      crop: {
        dragMode: 'move',
        aspectRatio: 180 / 180,
        viewMode: 1,
        ready: function () {
          setShowAvatarTips(false);
        }
      },
      canvasOption: {
        fillColor: '#fff',
      },
    });
  };
  const getUserInfo = async () => {
    const data = await api.userInfo.get();
    setUserInfo(data.data);
    setUserInfoValues({
      nickname: data.data.nickname,
      mobile: data.data.mobile,
      vocation: data.data.vocation,
      org_name: data.data.org_name,
    });
    if (!$('.ossuploader-add').length) {
      initAvatarComponent(data);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  useEffect(() => {}, [userInfoValues]);
  return (
    <>
      <Layout className="flex justify-center mt20">
        <div className="base-grid l-32 container">
          <div className="l">
            <Sidebar {...userInfo}></Sidebar>
          </div>
          <div className="r">
            <Modal
              className="m-modal"
              centered
              visible={noticeModal}
              width={365}
              onCancel={() => {
                closeFn();
              }}
            >
              <div className="m-notice-modal-content">
                <p className="modal-title">{notice.title}</p>
                <p className="modal-desc">{notice.desc}</p>
              </div>
            </Modal>
            <div className="base-section form-section">
              <div className="title-wrap">
                <h2 className="title">个人资料</h2>
              </div>
              {userInfoValues && (
                <Form
                  {...layout}
                  name="basic"
                  className="qs-form horizontal"
                  initialValues={userInfoValues}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item label="头像" name="avatar" required={true}>
                    <>
                    <input
                      className="ossuploader"
                      type="hidden"
                      id="avatar"
                      name="avatar"
                      value=""
                      data-srcjson=""
                    />
                    {showAvatarTips && <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">请上传头像</div></div>}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="姓名"
                    name="nickname"
                    rules={[{ required: true, message: '请输入姓名' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="手机"
                    name="mobile"
                    rules={[{ required: true, message: '请输入您的手机' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="职业"
                    name="vocation"
                    rules={[{ required: true, message: '请输入您的职业' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="所属机构"
                    name="org_name"
                    rules={[{ required: true, message: '请输入您的机构' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 3 }}>
                    <Button
                      className="cu-btn w-136 s-round large bg-yellow"
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loadings[0]}
                    >
                      保存修改
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default React.memo(Home);
